import React from "react";

import APlus from "../../../assets/images/filters-A+.svg";
import APlusPlus from "../../../assets/images/filters-A++.svg";
import APlusPlusPlus from "../../../assets/images/filters-A+++.svg";
import A from "../../../assets/images/filters-A.svg";
import B from "../../../assets/images/filters-B.svg";
import C from "../../../assets/images/filters-C.svg";
import D from "../../../assets/images/filters-D.svg";
import E from "../../../assets/images/filters-E.svg";
import F from "../../../assets/images/filters-F.svg";

const EnergyClassSymbol = ({ energyClass }) => {
	return (
		<img
			src={
				energyClass === "A+++"
					? APlusPlusPlus
					: energyClass === "A++"
					? APlusPlus
					: energyClass === "A+"
					? APlus
					: energyClass === "A"
					? A
					: energyClass === "B"
					? B
					: energyClass === "C"
					? C
					: energyClass === "D"
					? D
					: energyClass === "E"
					? E
					: energyClass === "F"
					? F
					: ""
			}
		/>
	);
};

export default EnergyClassSymbol;
