import React, { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { useTranslation } from "next-i18next";
import { useMediaQuery } from "react-responsive";
import EnergyClassSymbol from "../../SearchEngine/EnergyClassSymbol";
import { formatToCurrency } from "../../CostCalculator/city";
import HeartEmptyIcon from "../../../assets/images/heart-empty-icon.svg";
import moment from "moment";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";
import Image from "next/image";
import { useRouter } from "next/router";

const HomePagePropertyCard = ({ property }) => {
	const { routerPush } = useLinkTranslation();
	const { t } = useTranslation("steps");
	const router = useRouter();
	const { query, locale } = router;
	const [isSmall, setIsSmall] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmall(window.innerWidth <= 512);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return (
		<div
			className="properties-card"
			style={{
				height: "auto",
				width: isSmall ? "232px" : "339px",
				padding: 5,
				background: "white",
				borderRadius: 6,
				cursor: "pointer",
				// boxShadow: "0px 2px 3px 1px #1D2E5B0F",
				// cursor: "pointer",
			}}
			onClick={() => {
				routerPush(
					`/search/properties/${property?.listing_type === "FOR_RENT" ? "for-rent" : "for-sale"
					}?propertyId=${property?.id}`
				);
			}}
		>
			<div>
				<div style={{ position: "relative" }}>
					<div
						className="d-flex justify-content-between w-100"
						style={{ position: "absolute", padding: "10px 10px 0 10px" }}
					>
						{locale === 'fr' ? (
							<span className="timestamp">
								{(() => {
									try {
										const publishedDate = moment(property?.created_at);
										const timeAgo = publishedDate.fromNow();
										let count = null;

										if (timeAgo.includes("minutes") || timeAgo.includes("hours") || timeAgo.includes("days") || timeAgo.includes("weeks") || timeAgo.includes("months") || timeAgo.includes("years")) {
											count = parseInt(timeAgo.match(/\d+/)[0]);
										}

										if (timeAgo.includes("minute")) {
											return count > 1
												? `${t("ago")} ${count} ${t("minutes", { count })}`
												: `${t("ago")} ${t("a")} ${t("minute")}`;
										} else if (timeAgo.includes("hour")) {
											return count > 1
												? `${t("ago")} ${count} ${t("hours", { count })}`
												: `${t("ago")} ${t("an")} ${t("hour")}`;
										} else if (timeAgo.includes("day") && !timeAgo.includes("ago")) {
											return `${t("ago")} ${t("a")} ${t("day")}`;
										} else if (timeAgo.includes("day")) {
											return count > 1
												? `${t("ago")} ${count} ${t("days", { count })}`
												: `${t("ago")} ${t("a")} ${t("day")}`;
										} else if (timeAgo.includes("week")) {
											return count > 1
												? `${t("ago")} ${count} ${t("weeks", { count })}`
												: `${t("ago")} ${t("a")} ${t("week")}`;
										} else if (timeAgo.includes("month")) {
											return count > 1
												? `${t("ago")} ${count} ${t("months", { count })}`
												: `${t("ago")} ${t("a")} ${t("month")}`;
										} else if (timeAgo.includes("year")) {
											return count > 1
												? `${t("ago")} ${count} ${t("years", { count })}`
												: `${t("ago")} ${t("a")} ${t("year")}`;
										} else {
											return `${timeAgo}`;
										}
									} catch (error) {
										console.log(error);
									}
								})()}
							</span>
						) : (
							<span className="timestamp">
								{(() => {
									try {
										const publishedDate = moment(property?.created_at);
										const timeAgo = publishedDate.fromNow();
										let count = null;

										if (timeAgo.includes("minutes") || timeAgo.includes("hours") || timeAgo.includes("days") || timeAgo.includes("weeks") || timeAgo.includes("months") || timeAgo.includes("years")) {
											count = parseInt(timeAgo.match(/\d+/)[0]);
										}

										if (timeAgo.includes("minute")) {
											return count > 1
												? `${count} ${t("minutes", { count })} ${t("ago")}`
												: `${t("a")} ${t("minute")} ${t("ago")}`;
										} else if (timeAgo.includes("hour")) {
											return count > 1
												? `${count} ${t("hours", { count })} ${t("ago")}`
												: `${t("an")} ${t("hour")} ${t("ago")}`;
										} else if (timeAgo.includes("day") && !timeAgo.includes("ago")) {
											return `${t("a")} ${t("day")} ${t("ago")}`;
										} else if (timeAgo.includes("day")) {
											return count > 1
												? `${count} ${t("days", { count })} ${t("ago")}`
												: `${t("a")} ${t("day")} ${t("ago")}`;
										} else if (timeAgo.includes("week")) {
											return count > 1
												? `${count} ${t("weeks", { count })} ${t("ago")}`
												: `${t("a")} ${t("week")} ${t("ago")}`;
										} else if (timeAgo.includes("month")) {
											return count > 1
												? `${count} ${t("months", { count })} ${t("ago")}`
												: `${t("a")} ${t("month")} ${t("ago")}`;
										} else if (timeAgo.includes("year")) {
											return count > 1
												? `${count} ${t("years", { count })} ${t("ago")}`
												: `${t("a")} ${t("year")} ${t("ago")}`;
										} else {
											return `${timeAgo}`;
										}
									} catch (error) {
										console.log(error);
									}
								})()}
							</span>
						)}
						{/* <div className="fav-btn fav-btn-right">
							<img src={HeartEmptyIcon} style={{ objectFit: "cover" }} alt="" />
						</div> */}
					</div>
					<div
						style={{
							height: 180,
							width: "100%",
							position: "relative",
						}}
					>
						<img
							src={property?.images[0]?.url}
							style={{
								borderRadius: "6px 6px 0px 0px",
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
						/>
						{/* <Image
							layout="fill"
							objectFit="cover"
							src={property?.images[0]?.url}
							style={{
								borderRadius: "6px 6px 0px 0px",
							}}
						/> */}
					</div>
				</div>
				<div style={{ padding: 15 }}>
					<div style={{ fontSize: 18, fontWeight: "bold" }}>
						{property?.price ? formatToCurrency(property?.price) : "N/A"}
					</div>
					<div
						className="d-flex flex-row align-items-center"
						style={{ gap: 5 }}
					>
						<HiLocationMarker
							style={{
								color: "#8F99B4",
								height: 16,
								width: 16,
								marginTop: "-3px",
							}}
						/>
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								lineClamp: 1,
								whiteSpace: "nowrap",
								width: "90%",
								color: "var(--mainColor)",
							}}
						>
							{property?.search_address
								? property?.search_address?.split(",").slice(0, 3).join(",")
								: "N/A"}
						</div>
					</div>
					<div
						className="d-flex flex-row align-items-center flex-wrap"
						style={{
							color: "#6C768F",
							fontWeight: "bold",
							fontSize: 12,
							marginTop: 5,
							gap: 5,
						}}
					>
						<div style={{ textTransform: "capitalize" }}>
							{property?.property_type === "APARTMENT"
								? t("Apartment")
								: t("House")}
						</div>
						{property?.bedrooms && (
							<>
								{property?.property_type && (
									<div
										style={{
											width: 4,
											height: 4,
											borderRadius: 2,
											background: "#6C768F",
										}}
									/>
								)}
								<div>
									{`${property?.bedrooms === 1
										? `${property?.bedrooms} ${t("room")}`
										: `${property?.bedrooms ?? "N/A"} ${t("rooms")}`
										}`}
								</div>
							</>
						)}
						{property?.live_area && (
							<>
								{property?.bedrooms && (
									<div
										style={{
											width: 4,
											height: 4,
											borderRadius: 2,
											background: "#6C768F",
										}}
									/>
								)}
								<div>{`${property?.live_area}m²`}</div>
							</>
						)}
						{property?.energy_class && (
							<>
								{property?.live_area &&
									<div
										style={{
											width: 4,
											height: 4,
											borderRadius: 2,
											background: "#6C768F",
										}}
									/>
								}
								<EnergyClassSymbol energyClass={property?.energy_class} />
							</>
						)}
					</div>
				</div>
				{property?.agency_name && (
					<div
						style={{
							padding: 10,
							paddingLeft: 20,
							borderTop: "1px solid #E5EAF7",
							color: " #6C768F",
							textTransform: "uppercase",
							fontWeight: "bold",
							fontSize: 12,
						}}
					>
						{property?.agency_name}
					</div>
				)}
			</div>
		</div>
	);
};

export default HomePagePropertyCard;
