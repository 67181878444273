import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import RightArrow from "../../../assets/images/seo-home-page/forward-arrow.svg";
import LeftArrow from "../../../assets/images/seo-home-page/backward-arrow.svg";
import HomePagePropertyCard from "../HomePagePropertycard";
import { useSwipeable } from "react-swipeable";

const PropertySlider = ({ properties: _properties, t }) => {
	const [currentActive, setCurrentActive] = useState(0);
	const [properties, setProperties] = useState([..._properties]);
	const isSmall = useMediaQuery({
		query: "only screen and (max-width : 767px)",
	});
	const isMedium = useMediaQuery({
		query: "only screen and (min-width : 768px) and (max-width : 992px)",
	});
	const isLarge = useMediaQuery({
		query: "only screen and (min-width : 993px) and (max-width : 1199px)",
	});
	const isExtraSmall = useMediaQuery({
		query: "only screen and (max-width : 512px)",
	});
	const cardWidth = isExtraSmall ? 234 : 341;

	const moveLeft = () => {
		if (currentActive > 0) {
			setCurrentActive(currentActive - 1);
		}
	};

	const moveRight = () => {
		const maxVisibleIndex = properties.length - 5;

		if (currentActive < maxVisibleIndex) {
			setCurrentActive(currentActive + 1);
		} else {
			setCurrentActive(currentActive + 1);
			setProperties([...properties, ..._properties]);
		}
	};

	const swipeHandlers = useSwipeable({
		onSwipedLeft: moveRight,
		onSwipedRight: moveLeft,
	});

	return (
		<div
			style={{ position: "relative" }}
			{...swipeHandlers}
		>

			<div className="d-flex d-md-none"
				style={{
					position: "absolute",
					top: "22%",
					height: 380,
					margin: "0px 12px",
					alignItems: "center"
				}}>
				<button onClick={moveLeft} className="arrow-style">
					<img src={LeftArrow} />
				</button>
			</div>
			<div className="d-flex d-md-none"
				style={{
					position: "absolute",
					top: "22%",
					zIndex: 1,
					right: -318,
					width: "100%",
					margin: "0px",
					background: "linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 20%, rgba(255,255,255,0.6) 80%, rgba(255,255,255,0.6) 100%)",
					height: 380,
					alignItems: "center"
				}}>
				<button
					className="arrow-style"
					style={{ position: "absolute", right: "328px" }}
					onClick={moveRight}
				>
					<img src={RightArrow} />
				</button>
			</div>

			<div className="banner-content-wrapper banner-container-2">
				<div className="d-flex flex-row dream-property-section justify-content-between align-items-end slider-section-heading">
					<aside className="banner-con">
						<div className="dream-property">{t("fresh-on-the-market")}</div>
						<div className="dream-property-text">
							{t("browse-our-most-recent-listings")}
						</div>
					</aside>

					<div
						className="d-none d-md-flex justify-content-start align-items-center"
						style={{
							width: "20%",
							gap: "10px",
							marginTop: isMedium && "50px",
							marginLeft: isMedium && "40px",
						}}
					>
						<button onClick={moveLeft} className="arrow-style">
							<img src={LeftArrow} alt="Left Arrow" />
						</button>
						<button onClick={moveRight} className="arrow-style">
							<img src={RightArrow} alt="Right Arrow" />
						</button>
					</div>
				</div>

				<div
					style={{
						width: `calc(100vw - ${isSmall ? "50px" : isMedium ? "50px" : isLarge ? "50px" : "50px"
							})`,
						position: "relative",
						height: 366,
						overflow: "hidden",
						margin: "0px 12px"
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: 15,
							position: "absolute",
							transform: `translate(-${currentActive * (cardWidth + 15)
								}px, 0px)`,
							transition: "all 0.5s",
							padding: "5px 0px",
						}}
						{...swipeHandlers}
					>
						{properties.map((property, index) => (
							<div
								key={index}
								style={{
									boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
									borderRadius: "10px",
									marginLeft: 2,
								}}
							>
								<HomePagePropertyCard property={property} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PropertySlider;
