import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";
import RightArrow from "../../../assets/images/seo-home-page/forward-arrow.svg";
import LeftArrow from "../../../assets/images/seo-home-page/backward-arrow.svg";
import { Button } from "antd";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";
import Brussels from "../../../assets/images/seo-home-page/Brussels.png";
import Antwerp from "../../../assets/images/seo-home-page/Antwerp.png";
import Ghent from "../../../assets/images/seo-home-page/Ghent.png";
import Charleroi from "../../../assets/images/seo-home-page/Charleroi.png";
import Liège from "../../../assets/images/seo-home-page/Liège.png";
import Bruges from "../../../assets/images/seo-home-page/Bruges.png";
import Namur from "../../../assets/images/seo-home-page/Namur.png";
import Leuven from "../../../assets/images/seo-home-page/Leuven.png";
import Mons from "../../../assets/images/seo-home-page/Mons.png";
import Aalst from "../../../assets/images/seo-home-page/Aalst.png";
import Mechelen from "../../../assets/images/seo-home-page/Mechelen.png";
import LaLouvière from "../../../assets/images/seo-home-page/La Louvière.png";
import Kortrijk from "../../../assets/images/seo-home-page/Kortrijk.png";
import Hasselt from "../../../assets/images/seo-home-page/Hasselt.png";
import Ostend from "../../../assets/images/seo-home-page/Ostend.png";
import Image from "next/image";

const cities = {
	Brussels: Brussels,
	Antwerp: Antwerp,
	Ghent: Ghent,
	Charleroi: Charleroi,
	Liège: Liège,
	Bruges: Bruges,
	Namur: Namur,
	Leuven: Leuven,
	Mons: Mons,
	Aalst: Aalst,
	Mechelen: Mechelen,
	"La Louvière": LaLouvière,
	Kortrijk: Kortrijk,
	Hasselt: Hasselt,
	Ostend: Ostend,
};

const AppartmentSlider = ({ t, onClickCity, blockType }) => {
	const { routerPush, RouterLink } = useLinkTranslation();
	const [currentActive, setCurrentActive] = useState(0);
	// const [cities, setCities] = useState(_cities.slice(0, 10));
	const [displayedCities, setDisplayedCities] = useState(
		Object.keys(cities).slice(0, 10)
	);
	const isSmall = useMediaQuery({
		query: "(max-width : 767px)",
	});
	const isMedium = useMediaQuery({
		query: "(min-width : 768px) and (max-width : 992px)",
	});
	const isLarge = useMediaQuery({
		query: "(min-width : 993px) and (max-width : 1199px)",
	});
	const isExtraLarge = useMediaQuery({
		query: "(min-width : 1200px)",
	});
	const cardWidth = 250;

	const moveLeft = () => {
		if (currentActive > 0) {
			setCurrentActive(currentActive - 1);
		}
	};

	const moveRight = () => {
		if (currentActive < Object.keys(cities).length - 10) {
			setCurrentActive(currentActive + 1);
		} else {
			setCurrentActive(currentActive + 1);
			// setCities([...cities, ..._cities]);
			setDisplayedCities([...displayedCities, ...Object.keys(cities)]);
		}
	};

	const swipeHandlers = useSwipeable({
		onSwipedLeft: moveRight,
		onSwipedRight: moveLeft,
	});

	return (
		<div
			className="d-flex flex-column dream-property-section"
			style={{ position: "relative" }}
			{...swipeHandlers}
		>
			<div
				className="d-flex d-md-none"
				style={{
					position: "absolute",
					top: "26%",
					height: 380,
					margin: "0px 12px",
					alignItems: "center",
				}}
			>
				<button onClick={moveLeft} className="arrow-style">
					<img src={LeftArrow} />
				</button>
			</div>
			<div
				className="d-flex d-md-none"
				style={{
					position: "absolute",
					top: "26%",
					zIndex: 1,
					right: -324,
					width: "100%",
					margin: "0px",
					background:
						"linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.4) 20%, rgba(255,255,255,0.4) 80%, rgba(255,255,255,0.5) 100%)",
					height: 380,
					alignItems: "center",
				}}
			>
				<button
					className="arrow-style"
					style={{ position: "absolute", right: "332px" }}
					onClick={moveRight}
				>
					<img src={RightArrow} />
				</button>
			</div>
			<div className="banner-content-wrapper" style={{ position: "relative" }}>
				<div className="d-flex justify-content-between align-content-between slider-section-heading">
					<div className="d-flex flex-column banner-con">
						<div className="dream-property">
							{t(
								"your-dream-property-in-your-desired-city-is-just-a-click-away"
							)}
						</div>
						<div className="dream-property-text">
							{t("find-and-secure-your-dream-property-in")}
						</div>
					</div>

					<div
						className="d-none d-md-flex justify-content-start align-items-center"
						style={{
							width: "20%",
							gap: "10px",
							marginTop: isMedium && "50px",
							marginLeft: isMedium && "40px",
						}}
					>
						<button onClick={moveLeft} className="arrow-style">
							<img src={LeftArrow} />
						</button>
						<button onClick={moveRight} className="arrow-style">
							<img src={RightArrow} />
						</button>
					</div>
				</div>

				<div
					style={{
						width: `calc(100vw - ${
							isSmall ? "50px" : isMedium ? "50px" : isLarge ? "50px" : "50px"
						})`,
						position: "relative",
						height: 350,
						overflow: "hidden",
						margin: "0px 12px",
					}}
					{...swipeHandlers}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: 15,
							position: "absolute",
							translate: `-${
								currentActive === 0 ? 0 : currentActive * (cardWidth + 15)
							}px 0px`,
							transition: "all 0.5s",
							padding: "5px 0px",
						}}
					>
						{displayedCities.map((cityName, index) => (
							<div
								key={index}
								className="cards-trans"
								onClick={() =>
									routerPush(
										`/search/properties/${
											blockType === "buy" ? "for-sale" : "for-rent"
										}?search=${onClickCity(cityName)}`
									)
								}
								style={{
									width: `${cardWidth}px`,
									height: 340,

									overflow: "hidden",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "flex-end",

									position: "relative",
									boxShadow: "0px 1px 5px #00000062",
									borderRadius: 8,
									cursor: "pointer",
								}}
							>
								<div className="cards-trans-img w-100 h-100 position-absolute">
									<Image
										width={cardWidth}
										height={340}
										objectFit="cover"
										// src={`https://storage.googleapis.com/immobelgium-general/city_covers/${city_id}.webp`}
										src={cities[cityName]}
										alt={`Appartment ${index + 1}`}
										style={{
											// objectFit: "cover",
											// position: "absolute",
											// width: "100%",
											// height: "100%",
											borderRadius: 8,
										}}
									/>
								</div>
								<div
									className="cards-trans-bg"
									style={{
										position: "absolute",
										width: "100%",
										height: "100%",
										// background:
										// 	"linear-gradient(180deg, rgba(0, 0, 0, 0) 74.71%, #000000 100%)",
										borderRadius: 8,
									}}
								/>

								<div className="p-3 w-100">
									<RouterLink
										href={`/search/properties/${
											blockType === "buy" ? "for-sale" : "for-rent"
										}?search=${onClickCity(cityName)}`}
									>
										<Button
											block
											size="large"
											style={{
												background: "var(--colorBlue)",
												color: "white",
												fontWeight: "bold",
												borderRadius: 6,
											}}
										>
											{t(cityName)}
										</Button>
									</RouterLink>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppartmentSlider;
