import React, { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { useMediaQuery } from "react-responsive";
import PropertiesOverview from "../../../assets/images/seo-home-page/properties-overview.png";
import FiltersOverview from "../../../assets/images/seo-home-page/filters-overview.png";
import EmailAlert from "../../../assets/images/seo-home-page/email-alert.png";
import BuyHome from "../../../assets/images/seo-home-page/buy-home.svg";
import RentHome from "../../../assets/images/seo-home-page/rent-home.svg";
import EstimateHome from "../../../assets/images/seo-home-page/estimate-home.svg";
// import card1 from "../../../assets/images/seo-home-page/card-1.svg";
// import card2 from "../../../assets/images/seo-home-page/card-2.svg";
// import card3 from "../../../assets/images/seo-home-page/card-3.svg";
// import card4 from "../../../assets/images/seo-home-page/card-4.svg";
// import LocationImg from "../../../assets/images/seo-home-page/location-img.svg";
// import cardMbl1 from "../../../assets/images/seo-home-page/card-mbl-1.svg";
// import cardMbl2 from "../../../assets/images/seo-home-page/card-mbl-2.svg";
// import cardMbl3 from "../../../assets/images/seo-home-page/card-mbl-3.svg";
// import cardMbl4 from "../../../assets/images/seo-home-page/card-mbl-4.svg";
import PropertySlider from "../PropertySlider";
import AppartmentSlider from "../AppartmentSlider";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";
import { getCountOfSaleRentProperties } from "../../../network-requests/search-engine";
import { formatToCurrencyForCount } from "../../CostCalculator/city";
import Image from "next/image";

const getCityId = (city: string) => {
	switch (city) {
		case "Antwerpen":
			return "11002";
		case "Antwerp":
			return "11002";
		case "Gent":
			return "44021";
		case "Ghent":
			return "44021";
		case "Charleroi":
			return "52011";
		case "Luik":
			return "62063";
		case "Liège":
			return "62063";
		case "Saint Nicolas":
			return "62093";
		case "Brussel":
			return "21004";
		case "Brussels":
			return "21004";
		case "Schaarbeek":
			return "21015";
		case "Anderlecht":
			return "21001";
		case "Brugge":
			return "31005";
		case "Bruges":
			return "31005";
		case "Namen":
			return "92094";
		case "Namur":
			return "92094";
		case "Mons":
			return "53053";
		case "Aalst":
			return "41002";
		case "Mechelen":
			return "12025";
		case "La Louvière":
			return "58001";
		case "Kortrijk":
			return "34022";
		case "Hasselt":
			return "71022";
		case "Ostend":
			return "35013";
		case "Leuven":
			return "24062";
	}
};

const InfoBlock = ({ properties, t, blockType }) => {
	const isDesktop = useMediaQuery({
		query: "(max-width: 1600px) and (min-width: 1201px)",
	});
	const isTablet = useMediaQuery({ query: "(max-width: 992px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 498px)" });
	const [totalCount, setTotalCount] = useState();
	const [countForSale, setCountForSale] = useState();
	const [countForRent, setCountForRent] = useState();
	const [countForAgencies, setCountForAgencies] = useState();

	const { RouterLink } = useLinkTranslation();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const saleRentCounts = await getCountOfSaleRentProperties();
				setTotalCount(saleRentCounts.total_properties_count);
				setCountForSale(saleRentCounts.for_sale_count);
				setCountForRent(saleRentCounts.for_rent_count);
				setCountForAgencies(saleRentCounts.agencies_count);
			} catch (error) {
				console.error("Error fetching counts:", error);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="info-block">
			<div className="section-1 d-flex flex-column flex-lg-row align-items-center">
				<div className="col-6 d-none d-lg-block pr-4" style={{ height: 500 }}>
					<div className="w-100 h-100 position-relative">
						<Image
							layout="fill"
							width={"100%"}
							height={"100%"}
							objectFit="contain"
							src={PropertiesOverview}
						/>
					</div>
				</div>

				<div className="d-flex flex-column col-12 col-lg-6 p-0 pl-lg-4">
					<div className="md-section">
						<h2 className="sec-2-heading">
							{t("belgium-largest-selection-of")}
						</h2>
						<p
							className="sec-2-para main-text"
							// style={{ marginBottom: isMobile ? "calc(100vh - 750px)" : ""  }}
							style={{ marginBottom: isMobile ? "30px" : "" }}
						>
							{t("browse-select-and-secure-your-dream-property")}
						</p>

						<div
							style={{ height: 200 }}
							className="d-block d-lg-none pt-4 position-relative"
						>
							<Image
								src={PropertiesOverview}
								layout="fill"
								width={"100%"}
								height={"100%"}
								objectFit="contain"
							/>
						</div>
					</div>
					<div
						style={{ height: "max-content" }}
						className="w-100 d-flex flex-column stat-container"
					>
						<div
							style={{ gap: 20 }}
							className="w-100 d-flex flex-row align-items-start justify-content-between"
						>
							<div className="stat">
								<div className="val">
									{formatToCurrencyForCount(totalCount)}
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: t("total-number-of-properties"),
									}}
									className="stat-text"
								/>
							</div>
							<div className="stat">
								<div className="val">
									{formatToCurrencyForCount(countForAgencies)}
								</div>
								<div
									className="stat-text"
									dangerouslySetInnerHTML={{
										__html: t("total-number-of-agencies"),
									}}
								/>
							</div>
						</div>
						<div
							style={{ gap: 20 }}
							className="w-100 d-flex flex-row align-items-center justify-content-end"
						>
							<div className="stat">
								<div className="val">
									{formatToCurrencyForCount(countForSale)}
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: t("total-number-for-sale"),
									}}
									className="stat-text"
								/>
							</div>
							<div className="stat">
								<div className="val">
									{(countForRent && countForRent !== 0) ? formatToCurrencyForCount(countForRent) : "N/A"}
								</div>
								<div
									className="stat-text"
									dangerouslySetInnerHTML={{
										__html: t("total-number-for-rent"),
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AppartmentSlider
				onClickCity={getCityId}
				// cities={cities}
				t={t}
				blockType={blockType}
			/>
			{properties.length > 0 && (
				<PropertySlider t={t} properties={properties} />
			)}
			{/* <div
				className="container-fluid section-2"
				style={{
					padding: isDesktop ? "120px 156px" : "50px 32px",
				}}
			>
				<div className="row">
					<div className="card-heading-text">
						{t("featuring-the-leading-property-agencies")}
					</div>
				</div>
				<div
					className="row"
					style={{
						justifyContent: isDesktop ? "center" : "center",
						gap: isDesktop ? "12px" : "12px",
					}}
				>
					<div className="card ">
						<div className="card-header red-color">
							{isMobile ? (
								<img src={cardMbl1} alt="" className="h-100 w-100" />
							) : (
								<img src={card1} alt="" className="h-100 w-100" />
							)}
						</div>
						<div
							className="card-body d-flex flex-column align-items-center justify-content-center"
							style={{ gap: "16px" }}
						>
							<div className="address-container">
								<h3 className="m-0 card-title">Axel Lenaerts</h3>
								<div className="location">
									<img src={LocationImg} alt="" className="icon" />
									<span className="address">Antwerp</span>
								</div>
							</div>
							<div className="sale-rent-container">
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-sale")}
									</span>
									<span className="count-style">1,009</span>
								</div>
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-rent")}
									</span>
									<span className="count-style">1,009</span>
								</div>
							</div>
						</div>
					</div>
					<div className="card ">
						<div className="card-header">
							{isMobile ? (
								<img src={cardMbl2} alt="" className="h-100 w-100" />
							) : (
								<img src={card2} alt="" className="h-100 w-100" />
							)}
						</div>
						<div
							className="card-body d-flex flex-column align-items-center justify-content-center"
							style={{ gap: "16px" }}
						>
							<div className="address-container">
								<h3 className="m-0 card-title">InFinimo</h3>
								<div className="location">
									<img src={LocationImg} alt="" className="icon" />
									<span className="address">Antwerp</span>
								</div>
							</div>
							<div className="sale-rent-container">
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-sale")}
									</span>
									<span className="count-style">1,009</span>
								</div>
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-rent")}
									</span>
									<span className="count-style">1,009</span>
								</div>
							</div>
						</div>
					</div>
					<div className="card ">
						<div className="card-header">
							{isMobile ? (
								<img src={cardMbl3} alt="" className="h-100 w-100" />
							) : (
								<img src={card3} alt="" className="h-100 w-100" />
							)}
						</div>
						<div
							className="card-body d-flex flex-column align-items-center justify-content-center"
							style={{ gap: "16px" }}
						>
							<div className="address-container">
								<h3 className="m-0 card-title">Imo VG</h3>
								<div className="location">
									<img src={LocationImg} alt="" className="icon" />
									<span className="address">Antwerp</span>
								</div>
							</div>
							<div className="sale-rent-container">
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-sale")}
									</span>
									<span className="count-style">1,009</span>
								</div>
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-rent")}
									</span>
									<span className="count-style">1,009</span>
								</div>
							</div>
						</div>
					</div>
					<div className="card ">
						<div className="card-header">
							{isMobile ? (
								<img src={cardMbl4} alt="" className="h-100 w-100" />
							) : (
								<img src={card4} alt="" className="h-100 w-100" />
							)}
						</div>
						<div
							className="card-body d-flex flex-column align-items-center justify-content-center"
							style={{ gap: "16px" }}
						>
							<div className="address-container">
								<h3 className="m-0 card-title">VG Vastgoed</h3>
								<div className="location">
									<img src={LocationImg} alt="" className="icon" />
									<span className="address">Antwerp</span>
								</div>
							</div>
							<div className="sale-rent-container">
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-sale")}
									</span>
									<span className="count-style">1,009</span>
								</div>
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-rent")}
									</span>
									<span className="count-style">1,009</span>
								</div>
							</div>
						</div>
					</div>
					<div className="card ">
						<div className="card-header red-color">
							{isMobile ? (
								<img src={cardMbl1} alt="" className="h-100 w-100" />
							) : (
								<img src={card1} alt="" className="h-100 w-100" />
							)}
						</div>
						<div
							className="card-body d-flex flex-column align-items-center justify-content-center"
							style={{ gap: "16px" }}
						>
							<div className="address-container">
								<h3 className="m-0 card-title">Axel Lenaerts</h3>
								<div className="location">
									<img src={LocationImg} alt="" className="icon" />
									<span className="address">Antwerp</span>
								</div>
							</div>
							<div className="sale-rent-container">
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-sale")}
									</span>
									<span className="count-style">1,009</span>
								</div>
								<div className="total-properties-container">
									<span className="sale-style">
										{t("total-properties-for-rent")}
									</span>
									<span className="count-style">1,009</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center align-items-center">
					<RouterLink href={"/agencies"}>
						<a target="_blank">
							<button className="agency-button">
								{t("find-your-perfect-agency")}
							</button>
						</a>
					</RouterLink>
				</div>
			</div> */}
			<div className="section-1">
				<div className="row filter-class d-flex flex-md-column-reverse flex-lg-row flex-sm-column-reverse">
					<div className="col-lg-6 left-col-2 d-flex align-items-center">
						<div
							className="content"
							style={{
								marginTop: isDesktop ? "" : "10px",
							}}
						>
							<h2
								dangerouslySetInnerHTML={{
									__html: t("easy-filter-listings-on-a-detailed-map"),
								}}
								className="filter-heading-text"
							/>

							<p className="filter-heading-para main-text">
								{t(
									"at-belgiumImmo-we-understand-the-value-of-relevant-information"
								)}
							</p>
						</div>
					</div>
					<div className="col-lg-6 right-col-2 d-flex align-items-center">
						<div className="img-container w-100 h-100">
							<div style={{ height: 300 }} className="w-100 position-relative">
								<Image
									src={FiltersOverview}
									layout="fill"
									objectFit="contain"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="section-1"
				style={{
					paddingTop: "0px",
				}}
			>
				<div className="row">
					<div className="col-md-6 left-col d-md-flex">
						<div className="img-container w-100 h-100">
							<div className="w-100" style={{ height: 300 }}>
								<Image layout="fill" objectFit="contain" src={EmailAlert} />
							</div>
						</div>
					</div>
					<div className="col-md-6 right-col d-flex align-items-center">
						<div
							className="content"
							style={{
								marginTop: isDesktop ? "" : "10px",
							}}
						>
							<h2
								dangerouslySetInnerHTML={{
									__html: t("stay-updated-with-e-mail-alerts"),
								}}
								className=""
							/>

							<p className="filter-heading-para main-text">
								{t(
									"with-just-two-clicks-save-your-preferred-property-search-criteria"
								)}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid section-3">
				{/* <div className="row"> */}
				{/* <div className="col-lg-4 d-flex justify-content-center"> */}
				<div className="card">
					<div className="card-header res-con">
						<img src={BuyHome} alt="" />
					</div>
					<div className="card-body">
						<h2>{t("buy-a-home")}</h2>
						<p>{t("with-an-extensive-database")}</p>
					</div>
					<div className="card-footer">
						<RouterLink href={`/search/properties/for-sale`}>
							<button>{t("browse-homes")}</button>
						</RouterLink>
					</div>
				</div>
				{/* </div> */}
				{/* <div className="col-lg-4 d-flex justify-content-center"> */}
				<div className="card mx-32px">
					<div className="card-header res-con">
						<img src={RentHome} alt="" />
					</div>
					<div className="card-body">
						<h2>{t("rent-a-home")}</h2>
						<p>{t("embark-on-your-rental-property-search")}</p>
					</div>
					<div className="card-footer">
						<RouterLink href={`/search/properties/for-rent`}>
							<button>{t("find-rentals")}</button>
						</RouterLink>
					</div>
				</div>
				{/* </div> */}
				{/* <div className="col-lg-4 d-flex justify-content-center"> */}
				<div className="card">
					<div className="card-header res-con">
						<img src={EstimateHome} alt="" />
					</div>
					<div className="card-body">
						<h2>{t("estimate-a-home")}</h2>
						<p>{t("easily-assess")}</p>
					</div>
					<div className="card-footer">
						<RouterLink href="/new-estimate">
							<button>{t("estimate-home")}</button>
						</RouterLink>
					</div>
				</div>
				{/* </div> */}
				{/* </div> */}
			</div>
			<div className="container-fluid section-4">
				<div className="row d-flex crucial-point">
					<aside
						className="col-lg-6 d-flex flex-column"
						style={{ gap: "20px" }}
					>
						<div className="section-4-heading">
							<h5 className="real-estate-sale">{t("properties-for-sale")}</h5>
						</div>
						<div className="col-lg-12 d-flex resp-cont">
							<section className="col-lg-6  d-flex flex-column">
								{[
									"Brussels",
									"Antwerp",
									"Ghent",
									"Charleroi",
									"Liège",
									"Bruges",
									"Namur",
									"Leuven",
								].map((city) => {
									return (
										<RouterLink
											href={`/search/properties/for-sale?search=${getCityId(
												city
											)}`}
										>
											<a>
												<div
													style={{ cursor: "pointer" }}
													className="section-4-links"
												>
													{t(city)}
												</div>
											</a>
										</RouterLink>
									);
								})}
							</section>
							<section className="col-lg-6 d-flex flex-column">
								{[
									"Mons",
									"Aalst",
									"Mechelen",
									"La Louvière",
									"Kortrijk",
									"Hasselt",
									"Ostend",
								].map((city) => {
									return (
										<RouterLink
											href={`/search/properties/for-sale?search=${getCityId(
												city
											)}`}
										>
											<a>
												<div
													style={{ cursor: "pointer" }}
													className="section-4-links"
												>
													{t(city)}
												</div>
											</a>
										</RouterLink>
									);
								})}
							</section>
						</div>
					</aside>
					<aside
						className="col-lg-6 d-flex flex-column"
						style={{ gap: "20px" }}
					>
						<div className="section-4-heading">
							<h5 className="real-estate-sale">{t("properties-for-rent")}</h5>
						</div>
						<div className="col-lg-12 d-flex resp-cont">
							<div className="col-lg-6 d-flex flex-column">
								{[
									"Brussels",
									"Antwerp",
									"Ghent",
									"Charleroi",
									"Liège",
									"Bruges",
									"Namur",
									"Leuven",
								].map((city) => {
									return (
										<RouterLink
											href={`/search/properties/for-rent?search=${getCityId(
												city
											)}`}
										>
											<a>
												<div
													style={{ cursor: "pointer" }}
													className="section-4-links"
												>
													{t(city)}
												</div>
											</a>
										</RouterLink>
									);
								})}
							</div>
							<div className="col-lg-6 d-flex flex-column">
								{[
									"Mons",
									"Aalst",
									"Mechelen",
									"La Louvière",
									"Kortrijk",
									"Hasselt",
									"Ostend",
								].map((city) => {
									return (
										<RouterLink
											href={`/search/properties/for-rent?search=${getCityId(
												city
											)}`}
										>
											<a>
												<div
													style={{ cursor: "pointer" }}
													className="section-4-links"
												>
													{t(city)}
												</div>
											</a>
										</RouterLink>
									);
								})}
							</div>
						</div>
					</aside>
				</div>
			</div>
		</div>
	);
};

export default InfoBlock;
