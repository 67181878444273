import React, { useEffect, useState } from "react";
import BuyDesktop from "../../../assets/images/seo-home-page/buy-desktop.png";
import BuyTablet from "../../../assets/images/seo-home-page/buy-tablet.png";
import BuyMobile from "../../../assets/images/seo-home-page/buy-mobile.png";

import RentDesktop from "../../../assets/images/seo-home-page/rent-desktop.png";
import RentTablet from "../../../assets/images/seo-home-page/rent-tablet.png";
import RentMobile from "../../../assets/images/seo-home-page/rent-mobile.png";

import EstimateDesktop from "../../../assets/images/seo-home-page/estimate-desktop.png";
import EstimateTablet from "../../../assets/images/seo-home-page/estimate-tablet.png";
import EstimateMobile from "../../../assets/images/seo-home-page/estimate-mobile.png";

import { useTranslation } from "next-i18next";
import GoogleRatingBlock from "../../GoogleRatingBlock";
import { AutoComplete, Button, Divider, Input, Select, Tag } from "antd";
import debounce from "lodash/debounce";
import SearchIcon from "../../../assets/images/seo-home-page/search-icon.svg";
import {
	getProperties,
	searchAddressAutocomplete,
	searchQueryOnMapbox,
} from "../../../network-requests/search-engine";
import { useRouter } from "next/router";
import { fitBounds } from "@math.gl/web-mercator";
import { getBoundsFromViewState } from "../../SearchEngine";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { searchaddressOnMapbox } from "../../../network-requests";
import { useDispatch } from "react-redux";
import { openMainStepsAction } from "../../../actions";
import Image from "next/image";

const ImagesBlock = ({
	// setBlockType,
	blockType,
	rating,
	searchValues,
	setSearchValues,
}) => {
	const router = useRouter();
	const locale = router?.locale;
	const { routerPush, RouterLink } = useLinkTranslation();
	const { t } = useTranslation("seo-home-page");
	const { t: t2 } = useTranslation("search");
	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [searchOptions, setSearchOptions] = useState([
		// ...autoComplete.map(({ id, nl, fr, postalCode, level }) => {
		// 	return {
		// 		value: id,
		// 		label: (
		// 			<div className="d-flex flex-row align-items-center justify-content-between">
		// 				<div>
		// 					{level === 8
		// 						? `${locale === "fr" && fr ? fr : nl ? nl : fr} (+localities)`
		// 						: `${
		// 								locale === "fr" && fr ? fr : nl ? nl : fr
		// 						  } (${postalCode})`}
		// 				</div>
		// 				<MdOutlineArrowRightAlt
		// 					style={{ fontSize: 20, color: "var(--colorBlue)" }}
		// 				/>
		// 			</div>
		// 		),
		// 	};
		// }),
	]);
	const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
	const [dataFromMapBox, setdataFromMapbox] = useState([]);
	const [geoLocation, setGeoLocation] = useState({
		lat: null,
		lng: null,
	});
	const [dataInfo, setData] = useState({});
	const [value, setValue] = useState("");

	const dispatch = useDispatch();

	const agencyName = router.query?.agencyName;
	const source = router.query?.source;

	const handleSelectAddress = (addressId: string) => {
		const [currentAddress] = dataFromMapBox.filter(
			(list) => list.id === addressId
		);
		setValue(currentAddress.fullAddress);

		const dataForNextStep = {
			locality:
				currentAddress.locality.length > 1
					? currentAddress.locality
					: currentAddress.place,
			number: currentAddress.number,
			street: currentAddress.street,
			zip: currentAddress.postcode,
			country: currentAddress.country,
		};

		setData({ ...dataForNextStep });
		setGeoLocation(currentAddress.location);
		setdataFromMapbox([]);
	};

	const handleAutocomplete = async (el) => {
		setValue(el.target.value);
		if (el.target.value.length > 0) {
			const suggessions = await searchaddressOnMapbox(
				el.target.value,
				"address"
			);
			setdataFromMapbox([...suggessions]);
		} else {
			setValue("");
		}
	};

	const goToMainSteps = () => {
		if (value.length > 0) {
			const data = {
				infoFromAutoComplete: value,
				location: { ...geoLocation },
				additionalAddress: { ...dataInfo },
			};

			dispatch(openMainStepsAction(data));
		}

		let params = "";
		if (agencyName) {
			params = `agencyName=${agencyName}`;
		}
		if (source) {
			params += ` source=${source}`;
		}
		params = params.length > 0 ? "?" + params : params;
		params = params.replace(" ", "&");

		routerPush(`/new-estimate${params}`, undefined, { locale: router.locale });
	};

	// const onSearch = async (q: string) => {
	// 	try {
	// 		setIsSearching(true);
	// 		const results = await searchQueryOnMapbox(q, router.locale);
	// 		setSearchResults([...results]);

	// 		setSearchOptions([
	// 			...results.map((result) => {
	// 				return {
	// 					value: result?.id,
	// 					label:
	// 						result?.type === "postcode"
	// 							? `${result?.zip}, ${result[`city_${router?.locale}`]}`
	// 							: `${result[`city_${router?.locale}`]} (Whole city)`,
	// 				};
	// 			}),
	// 		]);
	// 		setIsSearching(false);
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	const onSearch = async (q: string) => {
		try {
			if (q.length > 1) {
				setIsSearching(true);
				const _results = await searchAddressAutocomplete(q);
				const results = _results.filter((result) => {
					if (result?.level === 9) {
						return _results.find(({ postalCode, level }) => {
							return postalCode === result?.postalCode && level === 8;
						}) &&
							_results.find(({ postalCode, level }) => {
								return postalCode === result?.postalCode && level === 10;
							})
							? false
							: true;
					} else {
						return true;
					}
					// return true;
				});
				setSearchResults([...results]);
				setSearchOptions([
					...results.map(({ id, fr, nl, level, postalCode }) => {
						return {
							label: (
								<div className="d-flex flex-row align-items-center justify-content-between w-100">
									<div>
										{level === 8
											? `${
													locale === "fr" ? fr : nl ? nl : fr
											  } (${postalCode}) (+${t2("localities")})`
											: `${
													locale === "fr" ? fr : nl ? nl : fr
											  } (${postalCode})`}
									</div>
									<MdOutlineArrowRightAlt
										style={{ fontSize: 20, color: "var(--colorBlue)" }}
									/>
								</div>
							),
							value: id,
						};
					}),
				]);
				// console.log(results);
				setIsSearching(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const imagesList = {
		buy: [isTablet ? BuyTablet : isMobile ? BuyMobile : BuyDesktop],
		rent: [isTablet ? RentTablet : isMobile ? RentMobile : RentDesktop],
		estimate: [
			isTablet ? EstimateTablet : isMobile ? EstimateMobile : EstimateDesktop,
		],
	};

	const AnimatedImage = ({ className, blockType, src }) => {
		return (
			<div
				style={{ transition: "all 1s", position: "relative" }}
				className={className}
			>
				<div
					className="buy-img"
					style={{ width: "100%", position: "absolute" }}
				>
					<Image
						className={blockType === "buy" ? "active" : "inactive"}
						src={imagesList["buy"][src]}
						layout="fill"
					/>
				</div>
				<div
					className="rent-img"
					style={{ width: "100%", position: "absolute" }}
				>
					<Image
						className={blockType === "rent" ? "active" : "inactive"}
						src={imagesList["rent"][src]}
						layout="fill"
					/>
				</div>
				<div
					className="estimate-img"
					style={{ width: "100%", position: "absolute" }}
				>
					<Image
						className={blockType === "estimate" ? "active" : "inactive"}
						src={imagesList["estimate"][src]}
						layout="fill"
					/>
				</div>
				{/* <img
					className={blockType === "rent" ? "active" : "inactive"}
					style={{ width: "100%", height: "100%", position: "absolute" }}
					src={imagesList["rent"][src]}
				/>
				<img
					className={blockType === "estimate" ? "active" : "inactive"}
					style={{ width: "100%", height: "100%", position: "absolute" }}
					src={imagesList["estimate"][src]}
				/> */}
			</div>
		);
	};

	useEffect(() => {
		setValue("");
	}, [blockType]);

	return (
		<section className="section">
			<div className="cover-block">
				<div
					className="d-none d-lg-block"
					style={{
						background: "white",
						height: "50%",
						width: "100%",
					}}
				></div>
				<div className="images-section">
					<div className="column-1">
						<AnimatedImage
							className={`${blockType}-1`}
							blockType={blockType}
							src={0}
						/>
					</div>
					<div className="search-box-container">
						<div className="search-box">
							<div className="button-block">
								<RouterLink href="/for-sale">
									<a className="btn-tag">
										<button
											className={`buy ${
												blockType === "buy" ? "active-" : ""
											}btn`}
										>
											{t("buy")}
										</button>
									</a>
								</RouterLink>
								<RouterLink href="/for-rent">
									<a className="btn-tag">
										<button
											className={`rent ${
												blockType === "rent" ? "active-" : ""
											}btn`}
										>
											{t("rent")}
										</button>
									</a>
								</RouterLink>
								<RouterLink href="/estimate-home">
									<a className="btn-tag">
										<button
											className={`estimate ${
												blockType === "estimate" ? "active-" : ""
											}btn`}
										>
											{t("estimate")}
										</button>
									</a>
								</RouterLink>
							</div>
							<h1 className="sub-heading">
								{locale === "fr" ? t(`${blockType}-z`) : t(blockType)}{" "}
								{t("your-dream-home")}
							</h1>
							{/* <div className="tagline mb-4">{t(`tagline-${blockType}-2`)}</div> */}
							<div
								className="search-bar"
								style={{ marginTop: isMobile && "4px" }}
							>
								<div className="input-container position-relative">
									{blockType === "estimate" ? (
										<AutoComplete
											style={{ width: "100%" }}
											options={dataFromMapBox.map(({ fullAddress, id }) => {
												return {
													label: fullAddress,
													value: id,
												};
											})}
											onSelect={(value, option) => {
												handleSelectAddress(value);
											}}
											value={value}
										>
											<Input
												// size="large"
												className="ml-0"
												placeholder={t(
													"search-bar-placeholder-estimate-home-page"
												)}
												style={{
													borderRadius: 0,
													outline: "none",
													border: "none",
													height: "100%",
													width: "100%",
													background: "transparent",
												}}
												onChange={(e) => handleAutocomplete(e)}
											/>
										</AutoComplete>
									) : (
										// <Select
										// 	className="se-autocomplete-searchbar"
										// 	style={{
										// 		width: "100%",
										// 		height: "100%",
										// 		// position: "absolute",
										// 		marginTop: isMobile ? -3 : 0,
										// 	}}
										// 	placeholder={t("search-bar-placeholder-home-page")}
										// 	showSearch
										// 	loading={isSearching}
										// 	bordered={false}
										// 	labelInValue
										// 	value={searchValues.map(({ id }) => {
										// 		const target = autoComplete.find(
										// 			({ id: _id }) => id === _id
										// 		);
										// 		const { level, fr, nl, postalCode } = target;
										// 		return {
										// 			value: target?.id,
										// 			label: (
										// 				<div className="d-flex flex-row align-items-center justify-content-between">
										// 					<div>
										// 						{level === 8
										// 							? `${locale === "fr" ? fr : nl ? nl : fr
										// 							} (+localities)`
										// 							: `${locale === "fr" ? fr : nl ? nl : fr
										// 							} (${postalCode})`}
										// 					</div>
										// 					<MdOutlineArrowRightAlt
										// 						style={{ fontSize: 20, color: "var(--colorBlue)" }}
										// 					/>
										// 				</div>
										// 			),
										// 		};
										// 	})}
										// 	options={searchOptions}
										// 	// open={true}
										// 	maxTagTextLength={10}
										// 	filterOption={(input, option) => {
										// 		const _option = autoComplete.find(
										// 			({ id }) => id === option.value
										// 		);
										// 		if (
										// 			`${_option?.nl}`
										// 				.toLowerCase()
										// 				.includes(`${input}`.toLowerCase()) ||
										// 			`${_option?.fr}`
										// 				.toLowerCase()
										// 				.includes(`${input}`.toLowerCase()) ||
										// 			_option?.aliases?.some((o: string) => {
										// 				return `${o}`
										// 					.toLowerCase()
										// 					.includes(`${input}`.toLowerCase());
										// 			}) ||
										// 			`${_option?.postalCode}`
										// 				.trim()
										// 				.includes(`${input}`.trim())
										// 		) {
										// 			if (_option?.level !== 9) {
										// 				return true;
										// 			} else {
										// 				if (_option?.children?.length === 0) {
										// 					return true;
										// 				} else {
										// 					return false;
										// 				}
										// 			}
										// 		} else return false;
										// 	}}
										// 	onDeselect={({ key }) => {
										// 		setSearchValues([
										// 			...searchValues.filter(({ id }) => key !== id),
										// 		]);
										// 	}}
										// 	onSelect={({ key }) => {
										// 		const targetValue = autoComplete?.find(
										// 			({ id }) => key === id
										// 		);

										// 		setSearchValues([
										// 			...searchValues,
										// 			{
										// 				...targetValue,
										// 			},
										// 		]);
										// 	}}
										// 	menuItemSelectedIcon={null}
										// 	dropdownMatchSelectWidth={true}
										// 	// dropdownMatchSelectWidth={isMobile ? 310 : 100}
										// 	dropdownRender={(menu) => {
										// 		return (
										// 			<div>
										// 				<div
										// 					className="w-100 d-flex flex-row align-items-center"
										// 					style={{
										// 						padding: 5,
										// 						paddingBottom: 0,
										// 						flexWrap: "wrap",
										// 					}}
										// 				>
										// 					{searchValues.map(({ id: _id }) => {
										// 						const target = autoComplete.find(
										// 							({ id }) => id === _id
										// 						);

										// 						const { level, postalCode, fr, nl } = target;
										// 						return (
										// 							<Tag
										// 								className="autocomplete-tag"
										// 								key={_id}
										// 								style={{
										// 									background: "#E5EAF7",
										// 									borderRadius: 4,
										// 									display: "inline-block",
										// 									fontSize: 13,
										// 									marginBottom: 5,
										// 								}}
										// 								closable
										// 								onClose={() =>
										// 									setSearchValues([
										// 										...searchValues.filter(
										// 											({ id }) => _id !== id
										// 										),
										// 									])
										// 								}
										// 							>
										// 								{level === 8
										// 									? `${locale === "fr" ? fr : nl ? nl : fr
										// 									} (+localities)`
										// 									: `${locale === "fr" ? fr : nl ? nl : fr
										// 									} (${postalCode})`}
										// 							</Tag>
										// 						);
										// 					})}
										// 				</div>
										// 				{searchValues.length > 0 && (
										// 					<Divider
										// 						style={{
										// 							marginTop: 5,
										// 							marginBottom: 10,
										// 							color: "#D2D5DE",
										// 						}}
										// 					/>
										// 				)}
										// 				{menu}
										// 			</div>
										// 		);
										// 	}}
										// 	mode="multiple"
										// 	maxTagCount={isMobile ? 1 : 2}
										// 	dropdownStyle={{
										// 		borderRadius: 6,
										// 		boxShadow: "0px 10px 24px 0px #1D2E5B3D",
										// 		padding: 5,
										// 	}}
										// 	tagRender={(props) => {
										// 		const { value, closable, onClose } = props;

										// 		const target = autoComplete.find(({ id }) => id === value);
										// 		const label =
										// 			locale === "fr"
										// 				? target?.fr
										// 					? target?.fr
										// 					: target?.nl
										// 				: target?.nl || "";
										// 		const onPreventMouseDown = (
										// 			event: React.MouseEvent<HTMLSpanElement>
										// 		) => {
										// 			event.preventDefault();
										// 			event.stopPropagation();
										// 		};
										// 		return (
										// 			<Tag
										// 				onMouseDown={onPreventMouseDown}
										// 				closable={closable}
										// 				onClose={onClose}
										// 				style={{
										// 					marginRight: 3,
										// 					borderRadius: 4,
										// 					background: "#E5EAF7",
										// 				}}
										// 			>
										// 				{`${label.slice(0, 10)}${label.length > 10 ? "..." : ""
										// 					}`}
										// 			</Tag>
										// 		);
										// 	}}
										// />
										<Select
											className="se-autocomplete-searchbar"
											style={{
												width: "100%",
												height: "100%",
												position: "absolute",
											}}
											filterOption={false}
											notFoundContent={null}
											defaultActiveFirstOption={false}
											placeholder={t("search-bar-placeholder-home-page")}
											showSearch
											loading={isSearching}
											bordered={false}
											labelInValue
											onSearch={debounce(onSearch, 500)}
											value={searchValues.map((target) => {
												const { level, fr, nl, postalCode } = target;
												return {
													value: target?.id,
													label: (
														<div className="d-flex flex-row align-items-center justify-content-between w-100">
															<div>
																{level === 8
																	? `${
																			locale === "fr" ? fr : nl ? nl : fr
																	  } (${postalCode}) (+${t2("localities")})`
																	: `${
																			locale === "fr" ? fr : nl ? nl : fr
																	  } (${postalCode})`}
															</div>
															<MdOutlineArrowRightAlt
																style={{
																	fontSize: 20,
																	color: "var(--colorBlue)",
																}}
															/>
														</div>
													),
												};
											})}
											options={searchOptions}
											maxTagTextLength={10}
											onDeselect={({ key }) => {
												setSearchValues([
													...searchValues.filter(({ id }) => key !== id),
												]);
											}}
											onSelect={({ key }) => {
												const targetValue = searchResults?.find(
													({ id }) => key === id
												);

												setSearchValues([
													...searchValues,
													{
														...targetValue,
													},
												]);
												routerPush(
													`/search/properties/${
														blockType === "buy" ? "for-sale" : "for-rent"
													}${`?search=${targetValue?.id}`}`
												);
											}}
											menuItemSelectedIcon={null}
											dropdownMatchSelectWidth={isMobile ? 310 : 500}
											dropdownRender={(menu) => {
												return (
													<div>
														<div
															className="w-100 d-flex flex-row align-items-center"
															style={{
																padding: 5,
																paddingBottom: 0,
																flexWrap: "wrap",
															}}
														>
															{searchValues.map((target) => {
																const { level, postalCode, fr, nl, id } =
																	target;
																return (
																	<Tag
																		className="autocomplete-tag"
																		key={id}
																		style={{
																			background: "#E5EAF7",
																			borderRadius: 4,
																			display: "inline-block",
																			fontSize: 13,
																			marginBottom: 5,
																		}}
																		closable
																		onClose={() =>
																			setSearchValues([
																				...searchValues.filter(
																					({ id: _id }) => _id !== id
																				),
																			])
																		}
																	>
																		{level === 8
																			? `${
																					locale === "fr" ? fr : nl ? nl : fr
																			  } (+${t2("localities")})`
																			: `${
																					locale === "fr" ? fr : nl ? nl : fr
																			  } (${postalCode})`}
																	</Tag>
																);
															})}
														</div>
														{searchValues.length > 0 && (
															<Divider
																style={{
																	marginTop: 5,
																	marginBottom: 10,
																	color: "#D2D5DE",
																}}
															/>
														)}
														{menu}
													</div>
												);
											}}
											mode="multiple"
											maxTagCount={2}
											dropdownStyle={{
												borderRadius: 6,
												boxShadow: "0px 10px 24px 0px #1D2E5B3D",
												padding: 5,
											}}
											tagRender={(props) => {
												const { value, closable, onClose } = props;

												const target = searchValues.find(
													({ id }) => id === value
												);
												const label =
													locale === "fr"
														? target?.fr
															? target?.fr
															: target?.nl
														: target?.nl || "";
												const onPreventMouseDown = (
													event: React.MouseEvent<HTMLSpanElement>
												) => {
													event.preventDefault();
													event.stopPropagation();
												};
												if (label) {
													return (
														<Tag
															onMouseDown={onPreventMouseDown}
															closable={closable}
															onClose={onClose}
															style={{
																marginRight: 3,
																borderRadius: 4,
																background: "#E5EAF7",
																display: "flex",
																alignItems: "center",
															}}
														>
															{`${label.slice(0, 10)}${
																label.length > 10 ? "..." : ""
															}`}
														</Tag>
													);
												} else {
													return null;
												}
											}}
										/>
									)}
								</div>
								{blockType === "estimate" ? (
									<>
										<div
											className="btn d-flex flex-row align-items-center justify-content-center"
											style={{
												fontWeight: "bold",
												cursor: "pointer",
											}}
											onClick={goToMainSteps}
										>
											<div>{t("search")}</div>
										</div>{" "}
									</>
								) : (
									<RouterLink
										href={`/search/properties/${
											blockType === "buy" ? "for-sale" : "for-rent"
										}${
											searchValues.length > 0
												? `?search=${searchValues.map(({ id }) => id)}`
												: ""
										}`}
									>
										<div
											className="btn d-flex flex-row align-items-center justify-content-center"
											style={{
												fontWeight: "bold",
												cursor: "pointer",
											}}
										>
											{/* <div>{t(blockType)}</div> */}
											<div>{t("search")}</div>
										</div>
									</RouterLink>
								)}
							</div>
							<div className="tagline" style={{ marginTop: "18px" }}>
								{t(`tagline-${blockType}-1`)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{ padding: "6rem 0rem 2rem 0rem" }}
				className="w-100 d-flex flex-row align-items-center justify-content-center justify-content-lg-center"
			>
				<GoogleRatingBlock
					alignHorizondally={true}
					isDarkBackground={false}
					isWhiteLogo={false}
					rating={rating}
				/>
			</div>
		</section>
	);
};

export default ImagesBlock;
