import React, { useState, useEffect } from "react";
import ImagesBlock from "./ImagesBlock";
import { useTranslation } from "next-i18next";
import InfoBlock from "./InfoBlock";
import EstimateHome from "../EstimateHome";
import RedirectModal from "./RedirectPopup";
import { useRouter } from "next/router";

const setCookie = (name, value) => {
	const date = new Date();
	date.setFullYear(date.getFullYear() + 30);
	document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

const getCookie = (name) => {
	const nameEQ = name + "=";
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
};

const SeoHomePage = ({ faqs, rating, properties, blockType }) => {
	const { t } = useTranslation("seo-home-page");
	const [searchValues, setSearchValues] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const router = useRouter();

	useEffect(() => {
		const { ref } = router.query;

		const redirectCookie = getCookie("redirect");

		if (ref === "redirect" && !redirectCookie) {
			setShowModal(true);
			setCookie("redirect", "true");
		}
	}, [router.query]);

	const handleModalClose = () => {
		setShowModal(false);
	};

	return (
		<div className="search-engine-home-page">
			<ImagesBlock
				// setBlockType={setBlockType}
				blockType={blockType}
				rating={rating}
				searchValues={searchValues}
				setSearchValues={setSearchValues}
			/>
			<div>
				{blockType === "estimate" ? (
					<div style={{ paddingTop: 100, background: "white" }}>
						<EstimateHome faqs={faqs} rating={rating} hideCover={true} />
					</div>
				) : (
					<InfoBlock blockType={blockType} t={t} properties={properties} />
				)}
			</div>
			{showModal && (
				<RedirectModal
					t={t}
					onClose={handleModalClose}
				/>
			)}
		</div>
	);
};

export default SeoHomePage;
