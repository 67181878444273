import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../Loading";
import redirect from "../../../assets/images/redirect-img.png";

const RedirectModal = ({ t, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        onClose();
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Modal
                className="redirect-modal"
                show={isModalVisible}
                onHide={handleCloseModal}
                centered
            >
                <Modal.Header
                    style={{ borderBottom: "none" }}
                    closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="redirect-modal-content">
                        <div className="redirect-title">
                            {t("Onze nieuwe naam is Proppy !")}
                        </div>
                        <div className="redirect-title">{t("Notre nouveau nom est Proppy !")}</div>
                        <div className="text-style mt-3"
                            style={{
                                fontWeight: "400",
                                padding: "0px 3px"
                            }}>
                            <div>
                                {t("We willen u informeren dat")}
                                <span className="ml-1"
                                    style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                        color: "#3871EF"
                                    }}>
                                    belgiumimmo.be
                                </span>
                            </div>
                            <div>
                                {t("nu")}
                                <span className="mr-1 ml-1"
                                    style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                        color: "#3871EF"
                                    }}>
                                    proppy.eu
                                </span>
                                {t("is geworden")}
                            </div>
                        </div>
                        <div className="text-style mt-3" style={{ fontWeight: "400" }}>
                            <div>
                                {t("Nous souhaitons vous informer que")}
                                <span className="ml-1"
                                    style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                        color: "#3871EF"
                                    }}>
                                    belgiumimmo.be
                                </span>
                            </div>
                            <div>
                                {t("est maintenant devenue")}
                                <span className="mr-1 ml-1"
                                    style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                        color: "#3871EF"
                                    }}>
                                    proppy.eu
                                </span>
                            </div>
                        </div>
                        <img className="redirect-img" src={redirect} alt="Redirect" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RedirectModal;
